<template>
    <div class="oml-smsEmailTemp-container">
        <div class="oml-smsEmailTemp-content">
            <div class="oml-smsEmailTemp-header">
                <b-row>
                    <b-col cols="12">
                        <div class="oml-smsEmailTemp-title">
                            <h6>{{$t('email_templates')}}
							<i v-b-modal="'addTemplates'" class="fa fa-plus-circle" style="color:#377cff;font-size: 17px;margin-left: 7px;"></i>
							</h6>
							
							<div class="p-desc">
								<p>{{ $t('desc_emailTemplate') }}</p>
							</div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="oml-smsEmailTemp-body">
				<data-table :loadTemplates="loadTemplates" :countTempMethod="passCountTemp" :emailTemplates="emailTemplates" :columns="gridColumns"></data-table>
				
				
            </div>
        </div>

        <b-modal id="addTemplates" size="lg" hide-footer @shown="test()" @hidden="close('addTemplates')"  :title="$t('add_email_template')">
            <div class="custom-tabs setting-tabs mt-2">
				<div class="form-row m-0">
					<div class="form-group col-md-12 pl-0">
						<label for="name">{{$t('title')}} <b style="color:red;">*</b></label>
						<input type="text" class="form-control" v-model="emailTemplate.title" placeholder="" />
						<small v-if="errors.title" style="color: red;"> * {{ $t('title_required') }} </small>
						<!--:placeholder="$t('type_your_template_title_here')"-->
					</div>
					<div class="form-group col-md-12 pl-0">
						<label for="name">{{$t('subject')}} <b style="color:red;">*</b></label>
						<input type="text" class="form-control " v-model="emailTemplate.subject" placeholder="" />
						<small v-if="errors.subject" style="color: red;"> * {{ $t('subject_required') }} </small>
						<!--:placeholder="$t('write_the_email_reason_here')"-->
					</div>
					<div class="form-group col-md-12 pl-0">
						<label for="name">{{$t('body')}} <b style="color:red;">*</b></label>
						<vue-editor id="template_body" :editorOptions="editorOptions" ref="editorMd" v-model="emailTemplate.description" class="oml-vueEditor smsEmail-templates"></vue-editor>
						<small v-if="errors.body" style="color: red;"> * {{ $t('body_required') }} </small>
					</div>

					<div class="form-group col-md-12 pl-0">
						<div class="modal-actions">
							<button class="oml-btn outlined gray-btn mr-3" @click="[cancel('add'),$bvModal.hide('addTemplates')]">{{$t('template_cancel')}}</button>
							<button class="oml-btn" @click="addEmailTemplate('email')">{{$t('template_save')}}</button>
						</div>
					</div>
				</div>
            </div>

        </b-modal>
        
    </div>
</template>

<script>
	import { VueEditor } from "vue2-editor";
	import dataTable from '@/plugins/md/dataTable/datatable-emailTemplate.vue';
	
    export default {
        name: "sms-email",
        components: {
			VueEditor,
			dataTable
		},
        data() {
            return {
                addTemplates:false,
                collapseEmail: true,
                collapseSms: false,
                emailTemplate:{
                    title:'',
                    subject:'',
                    description:'',
                    time:''
                },
                emailTemplates:[],
                smsTemplates:[],
				editEmailTemplate:{},
                content: '<p>example content</p>',
                editorOption: { /* quill options */ },
				
				editorOptions: {
					modules: {
						toolbar: {
							container: [
								[{'placeholder': ['[[prospect_first_name]]','[[prospect_last_name]]','[[prospect_full_name]]', '[[prospect_phone]]', '[[prospect_email]]']}],
								[{'size': ['small', false, 'large', 'huge']}],
								[{'font': []}],
								['bold', 'italic', 'underline', 'link', {'color': []}],
								[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
								[{'direction': 'rtl'}],
								[{'align': []}],
								['clean']
							],
							handlers: {
								"placeholder": function (value) {
									if (value) {
										const cursorPosition = this.quill.getSelection().index;
										this.quill.insertText(cursorPosition, value);
										this.quill.setSelection(cursorPosition + value.length);
									}
								}
							}
						}
					}
                },
                searchQuery:'',
                errors:{},
				
				gridColumns	: [
					{ title:  this.$t('Title'), column: 'title'},
					{ title:  this.$t('Subject'), column: 'subject'},
					{ title:  this.$t('Body'), column: 'description'},
					{ title: this.$t('CreatedAt'), column: 'time'},
					{ title: this.$t('Action'), column: 'action'}
				],
            }
        },
        mounted() {
            let that = this;
            that.loadTemplates("email");
			
			that.passCountTemp()
        },
        methods: {
            addEmailTemplate(type) {
                console.log(type)
                let that = this;
                that.errors = {};
                let url = "add_template";
                let method = "post";
                let data = [];
                if (that.emailTemplate.title == '') {
                    that.errors['title']  = 'Title Required';
                }
                if(that.emailTemplate.subject == '') {
                    that.errors['subject'] = 'Subject Required';
                }
                if(that.emailTemplate.description == '') {
                    that.errors['body'] = 'Body Required';
                }
                if (Object.keys(that.errors).length == 0) {
                    data = {
                      title: that.emailTemplate.title,
                      subject: that.emailTemplate.subject,
                      body: that.emailTemplate.description,
                      type: 'email',
                    };
                    that.$store
                        .dispatch("apiRequest", { url, method, data })
                        .then((response) => {
                            let feedback = response.data;
                            if (feedback.success) {
                                that.loadTemplates("email");
                                that.notifyMe(feedback.message, "success");
                                that.$bvModal.hide('addTemplates');
                            }
                    })
                    .catch(({ response }) => {
                        that.notifyMe(response.data.message,"warn");
                    });
                }
				
				that.passCountTemp()
            },
            convertDate(date){
                var now = Date.now();
                var dateC = Date.parse(date);

                console.log(now + ' ---- ' + dateC);

                var diffMs = (now - dateC);

                var hours = Math.floor((diffMs % 86400000) / 3600000);

                var val = "";

                if(hours < 1){
                    var diff = Math.round(((diffMs % 86400000) % 3600000) / 60000);

                    if(diff == 0){
                        val = 'Just now'
                    }else{
                        val = diff + " Minutes ago"
                    }
                }else if (hours >= 1 && hours <= 24){
                    val = hours + " Hours ago";
                }else{
                    var diffDays = Math.floor(diffMs / 86400000);

                    val = diffDays + " Days ago";
                }
                return val
            },
            loadTemplates(type) {
                let that   = this;
                let method = "GET";
                let url    = "templates";
                let data   = {type:type,keyword:that.searchQuery};
                that.$store.dispatch("apiRequest", {url,method,data}).then((response) => {
                    let templates = response.data.templates;
                    that.emailTemplates = [];
                    templates.forEach(function(template) {
                        if (type == "sms") {
                            console.lof(type )
                        } else if (type == "email") {
                              that.emailTemplates.push({
                                id:template.id,
                                title:template.title,
                                subject:template.subject,
                                description:template.body,
                                time:template.created_at, 
                              });
                        }
                    });
					
					that.passCountTemp()
                })
                .catch((response) => {
                    console.log(response)
                });
				
				
            },
            onEditorBlur(event){
                console.log(event)
            },
            onEditorFocus(event){
                console.log(event)
            },
            onEditorReady(event){
                console.log(event)
            },
			
            cancel(type) {
                if(type == 'add') {
                    let that = this;
                    that.emailTemplate.title        = '';
                    that.emailTemplate.subject      = '';
                    that.emailTemplate.description  = '';
                    that.emailTemplate.time         = '';
                } else {
                    let that = this;
                    console.log(type);
                    console.log(that);
                    that.loadTemplates("email");
                }
            },
			adjustLeadInfoEditorDropdown: function () {
				var element = document.getElementsByClassName("ql-selected");
				if(element.length > 0) {
					element[0].classList.remove("ql-selected");
				}
				let placeholderPickerItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
                if(placeholderPickerItems){
                    placeholderPickerItems.forEach(item => item.textContent = item.dataset.value);
                    const label = document.querySelector('.ql-placeholder .ql-picker-label');
                    if(label){
                        //label.setAttribute('data-value','Lead infos');
                        label.innerHTML = '';	
                        label.innerHTML = 'Lead infos <svg viewBox="0 0 18 18"> <polygon class="ql-stroke" points="7 11 9 13 11 11 7 11"></polygon> <polygon class="ql-stroke" points="7 7 9 5 11 7 7 7"></polygon> </svg>';
                    }
                }
			},
			test: function(){
				let that = this;
				that.adjustLeadInfoEditorDropdown();
			},
            close: function(modal_type){
                if(modal_type == 'addTemplates'){
                    let that = this;
                    that.errors = {};
                    that.emailTemplate.title        = '';
                    that.emailTemplate.subject      = '';
                    that.emailTemplate.description  = '';
                    that.emailTemplate.time         = '';
                } else {
                    let that = this;
                    that.errors = {};
                }   
            },
			passCountTemp:function(){
				let cntTemplates = this.emailTemplates.length;
				this.$emit('countTemplates',cntTemplates)
			}
        },
        filters: {
            truncate: function (text) {
                if (text.length > 300) {
                    return text.substring(0, 300) + ' ...'
                } else {
                    return text
                }
            }
        },
		
    }
</script>

<style lang="scss">
    .oml-smsEmailTemp-container{
        .oml-smsEmailTemp-content{
            .oml-smsEmailTemp-header{
                .oml-smsEmailTemp-title{
                    h6{
                        font-weight:700;
                        color:#1d2841;
                        display:flex;
                        align-items:center;

                        span{
                            width:25px;
                            height:25px;
                            background-color:#1fbbf6;
                            color:#fff;
                            border-radius:100%;
                            display:flex;
                            align-items:center;
                            justify-content:center;
                            font-size:14px;
                            margin-left:10px;
                        }
                    }
                }

                .oml-smsEmailTemp-action{
                    display:flex;
                    align-items:center;
                    justify-content:flex-end;

                    .form-group{
                        position:relative;
                        margin:0 10px 0 0;

                        &:before{
                            content:"\f002";
                            font: normal normal normal 14px/1 FontAwesome;
                            position:absolute;
                            top: 0;
                            bottom: 0;
                            left: 8px;
                            color: #dadfe7;
                            font-size: 13px;
                            height: 13px;
                            margin: auto;
                        }

                        input{
                            padding-left:25px;
                            min-height:40px;
							color:#999;
							font-size:12px;
							
							&::placeholder { 
								font-size: 12px;
								color:#999;
							}
							
							&:-ms-input-placeholder {
								font-size: 12px;
								color:#999;
							}
							
							&::-ms-input-placeholder {
								font-size: 12px;
								color:#999;
							}
                        }
                    }
                    button{
                        background-color:#00bbf6;
                        border:1px solid #00bbf6;
                        border-radius:5px;
                        color:#fff;
                        font-size:14px;
                        font-weight:300;
                        display:flex;
                        align-items:center;
                        padding-left:15px;
                        padding-right:15px;
                        min-height:34px;

                        span{
                            font-size:18px;
                            margin-right:8px;
                        }
                    }
                }
            }

            .oml-smsEmailTemp-body{
                .oml-accordion{
                    margin-top:10px;
					
                    .oml-accordion-panel{
                        border-radius:10px;
                        margin-bottom:30px;
                        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                        .oml-accordionPanel-header{
                            display:flex;
                            align-items:center;
                            justify-content:space-between;
                            padding:10px 15px;
                            cursor:pointer;

                            .oml-accordionPanelHeader-title{
                                display:flex;
                                align-items:center;

                                img{
                                    max-height:22px;
                                    margin-right:12px;
                                }

                                h6{
                                    margin:0;
                                    color:#1d2841;
                                    font-size:15px;
                                    margin-right:12px;
                                }

                                .countTemplates{
                                    border-radius:5px;
                                    display:flex;
                                    align-items:center;
                                    padding:4px 8px;
                                    font-size:13px;

                                    span{
                                        width:20px;
                                        height:20px;
                                        font-size:12px;
                                        border-radius:100%;
                                        display:flex;
                                        align-items:center;
                                        justify-content:center;
                                        color:#fff;
                                        margin-right:6px;
                                    }

                                    &.email{
                                        background-color:#f1faff;
                                        color:#1fbbf6;

                                        span{
                                            background-color:#1fbbf6;
                                        }
                                    }

                                    &.sms{
                                        background-color:#fff9e6;
                                        color:#b48806;

                                        span{
                                            background-color:#b48806;
                                        }
                                    }

                                }
                            }

                            .oml-accordionPanelHeader-icon{
                                width:40px;
                                height:40px;
                                border-radius:100%;
                                background-color:#eefbff;
                                display:flex;
                                align-items:center;
                                justify-content:center;
                                color:#2db0d9;

                                i{
                                    font-size:22px;
                                }
                            }
                        }

                        .oml-accordionPanel-body{
                            border-top:1px solid #efefef;
                            padding:15px;

                            .oml-accordionPanel-templates{
                                .oml-accordionPanel-template{
                                    border-bottom:1px solid #efefef;
                                    padding-bottom:10px;
                                    margin-top:10px;

                                    &:last-child{
                                        border-bottom:0px solid transparent;
                                    }

                                    .oml-accordionPanelTemp-infos{
                                        h6{
                                            font-size:15px;
                                            font-weight:600;
                                            color:#4d4d4d;
                                        }

                                        p{
                                            font-size:14px;
                                            font-weight:400;
                                            color:#4d4d4d;
                                            margin-bottom:5px;
                                        }

                                        .time{
                                            font-size:13px;
                                            font-weight:400;
                                            color:#1fbbf6;
                                        }
                                    }
                                    .oml-accordionPanelTemp-actions{
                                        .btn{
                                            border-radius:3px;
                                            padding:8px 15px;

                                            i{
                                                font-size:17px;
                                                margin-right:10px;
                                            }

                                            &.edit-btn{
                                                border:1px solid #20bcf3;
                                                background-color:#fff;
                                                color:#20bcf3;
                                                margin-right:10px;
                                            }

                                            &.view-btn{
                                                border:1px solid #20bcf3;
                                                background-color:#20bcf3;
                                                color:#fff;
                                            }
                                        }
                                    }
                                }
                            }

                            .oml-accordionPanel-empty{
                                padding:50px 0;
                                text-align:center;
                                display:flex;
                                align-items:center;
                                justify-content:center;

                                h5{
                                    font-weight: 700;
                                    color: #1d2841;
                                    font-size:20px;
                                }

                                p{
                                    font-weight: 400;
                                    color: #1d2841;
                                    font-size:14px;
                                    max-width:300px;
                                }

                                button{
                                    min-width:250px;
                                    border-radius:4px;
                                    padding:8px 15px;
                                    color:#fff;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
									font-family: poppins,sans-serif;
									font-size:14px;
									font-weight:400;

                                    span{
                                        font-size: 24px;
                                        color: #fff;
                                        margin-right: 10px;
                                        line-height: 20px;
                                    }

                                    &.email{
                                        background-color:#00bbf6;
                                        border:1px solid #00bbf6;
										
										&:hover{
											background-color:#01a3d6 !important;
										}
                                    }

                                    &.sms{
                                        background-color:#fec12d;
                                        border:1px solid #fec12d;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }



    }

    #addTemplates,#editTemplates{
        header{
            border-bottom:0px solid transparent !important;

            button{
                position: absolute;
                right: 16px;
                z-index: 1;
            }

            h5{
                font-size:16px;
                font-weight:600;
            }
        }

        .form-group{
            label{
                font-size:14px;
                font-weight:500;
            }
        }

        p{
            font-size:14px;
            font-weight:400;
        }

        .modal-actions{
            display:flex;
            align-items:flex-end;
            justify-content: flex-end;
            height:100%;

            button{
                padding:6px 16px;
                min-width:140px;
                font-size:14px;

                &.cancel-btn{
                    background-color:#fff;
                    border:1px solid #8d8d8d;
                    border-radius:5px;
                    color:#8d8d8d;
					height:40px;
                }

                &.save-btn{
                    background-color:#06aff3;
                    border:1px solid #06aff3;
                    border-radius:5px;
                    color:#fff;
                }
            }
        }

        .custom-tabs{
            margin-top:-40px;

            .ql-editor{
                min-height:150px;
            }
        }
    }
	
	.oml-vueEditor.smsEmail-templates{

		.ql-toolbar{
			&:before{
				width:5px;
			}

			&:after{
				width:5px;
			}

            .ql-placeholder .ql-picker-label{
                display:flex;
                align-items:center;
            }
		}
	}
</style>